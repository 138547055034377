<template>
  <div class="product-details mt-14">


    <div class="max-width">
      <!-- <div class="custom-tabs__tabs d-flex align-start justify-space-start ">
        <div
          class="custom-tabs__tabs__tab text-center cursor-pointer mx-5 px-10 py-1"
          :class="tab.active ? 'custom-tabs__tabs__tab--active' : ''"
          v-for="(tab, i) in tabs"
          :key="i"
          @click="$router.push(tab.route)"
        >
          <span
            class="font-25"
            :class="tab.active ? 'halfwhite--text' : 'grey--text'"
            >{{  $t(tab.title) }}</span
          >
        </div>
      </div> -->
      <div class="">
        <div
          class=""
          :class="tab.active ? '' : ''"
          v-for="(tab, i) in tabs"
          :key="i"
          @click="$router.push(tab.route)"
        >
          <h2
            class="font-35 text-primary"
            
            >{{  $t(tab.title) }}</h2
          >
        </div>
      </div>
      
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
   
  </div>
</template>

<script>
export default {
  data: () => ({
    tabs: [
      {
        title: "OfflineProduct",
        active: false,
        route: "/buy-product/offline",
      },
      // {
      //   title: "AddLink",
      //   active: true,
      //   route: "/buy-product/online",
      // },
    ],
  }),
  watch: {
    $route: {
      handler() {
        this.checkRoute();
      },
    },
  },
  methods: {
    checkRoute() {
      if (this.$route.name == "Offline Product") {
        this.tabs[0].active = false;
        this.tabs[1].active = true;
      } else {
        this.tabs[0].active = true;
        this.tabs[1].active = false;
      }
    },
  },
  async created() {
    this.checkRoute();
  },
  beforeRouteEnter(_, _2, next) {
    if (
      localStorage.getItem("role") == "bringer" ||
      localStorage.getItem("role") == "sender"
    ) {
      next();
    } else if (localStorage.getItem("role") == "guest") {
      next("/verification");
    } else {
      next("/sign-in");
    }
  },
};
</script>

<style lang="scss"></style>
